<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 11L46 7"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M46 11L50 7"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0739 45.2068V52.7476C10.0739 52.7476 10.0739 52.7852 10.0739 52.8039C10.0739 55.674 18.5675 58 29.0369 58C39.5063 58 47.9446 55.6927 48 52.8227V44.9254L47.483 44.3064L10.0739 45.188V45.2068Z"
      fill="white"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M29.0369 49.9714C39.5099 49.9714 48 47.6451 48 44.7754C48 41.9056 39.5099 39.5793 29.0369 39.5793C18.5639 39.5793 10.0739 41.9056 10.0739 44.7754C10.0739 47.6451 18.5639 49.9714 29.0369 49.9714Z"
      fill="white"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0739 33.5579V40.9862C10.0739 40.9862 10.0739 41.0237 10.0739 41.0425C10.0739 43.9125 18.5675 46.2386 29.0369 46.2386C39.5063 46.2386 47.9446 43.9313 48 41.0612V33.5579H10.0739Z"
      fill="#EEEEF4"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M29.0369 38.21C39.5099 38.21 48 35.8836 48 33.0139C48 30.1442 39.5099 27.8178 29.0369 27.8178C18.5639 27.8178 10.0739 30.1442 10.0739 33.0139C10.0739 35.8836 18.5639 38.21 29.0369 38.21Z"
      fill="#EEEEF4"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M47.9261 21.6275L10 21.6838V29.1684C10 29.1684 10 29.2059 10 29.2247C10 32.0947 18.4937 34.4207 28.9631 34.4207C39.3955 34.4207 47.8707 32.1134 47.9261 29.2434V21.6275Z"
      fill="white"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M28.9631 26.3921C39.4361 26.3921 47.9261 24.0658 47.9261 21.1961C47.9261 18.3264 39.4361 16 28.9631 16C18.4901 16 10 18.3264 10 21.1961C10 24.0658 18.4901 26.3921 28.9631 26.3921Z"
      fill="white"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M44.0552 41C44.5988 41 45.0395 40.5523 45.0395 40C45.0395 39.4477 44.5988 39 44.0552 39C43.5115 39 43.0708 39.4477 43.0708 40C43.0708 40.5523 43.5115 41 44.0552 41Z"
      fill="#797997"
    />
    <path
      d="M44.0552 53C44.5988 53 45.0395 52.5523 45.0395 52C45.0395 51.4477 44.5988 51 44.0552 51C43.5115 51 43.0708 51.4477 43.0708 52C43.0708 52.5523 43.5115 53 44.0552 53Z"
      fill="#B0B0D3"
    />
    <path
      d="M44.0552 29C44.5988 29 45.0395 28.5523 45.0395 28C45.0395 27.4477 44.5988 27 44.0552 27C43.5115 27 43.0708 27.4477 43.0708 28C43.0708 28.5523 43.5115 29 44.0552 29Z"
      fill="#B0B0D3"
    />
    <path
      d="M58 20C59.1046 20 60 19.1046 60 18C60 16.8954 59.1046 16 58 16C56.8954 16 56 16.8954 56 18C56 19.1046 56.8954 20 58 20Z"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <v-col cols="12" lg="6" class="responsive-card">
    <v-card elevation="4" class="monitoring-card">
      <v-card-title>
        <h6 class="mb-4">{{ title }}</h6>
      </v-card-title>
      <v-card-text class="data-container" v-if="!loading">
        <div class="chart">
          <pie-chart :chartdata="chartdata" :colors="colors" :labels="labels" />
        </div>
        <ul class="data-list">
          <li
            class="list-item"
            :style="{ color: item.color }"
            v-for="(item, index) in data"
            :key="index"
          >
            <span
              ><b class="font-weight-600">{{ item.label }}</b></span
            >
            <span
              ><b class="font-weight-600">{{ item.data }}</b></span
            >
          </li>
        </ul>
      </v-card-text>
      <pie-chart-skeleton v-else />
    </v-card>
  </v-col>
</template>

<script>
import PieChart from "../../charts/PieChart.vue";
import PieChartSkeleton from "./PieChartSkeleton.vue";

export default {
  components: {
    PieChart,
    PieChartSkeleton,
  },
  data: () => ({}),
  computed: {
    chartdata: function () {
      return this.data.map((item) => {
        return item.data;
      });
    },
    colors: function () {
      return this.data.map((item) => {
        return item.color;
      });
    },
    labels: function () {
      return this.data.map((item) => {
        return item.label;
      });
    },
  },
  props: {
    loading: Boolean,
    title: String,
    data: {
      type: Array,
      default: () => [
        {
          data: 40,
          label: "a",
          color: "#83CC80",
        },
        {
          data: 20,
          label: "c",
          color: "#EF9840",
        },
        {
          data: 30,
          label: "b",
          color: "#CA7699",
        },
      ],
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  max-height: 180px;
  max-width: 180px;
}
.data-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.data-list {
  padding-left: 48px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.list-item {
  list-style: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  span {
    color: map-get($text, headings);
    display: block;
    font-weight: $font-weight-medium;
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 4px);
    width: 8px;
    height: 8px;
    background-color: currentColor;
    border-radius: 50%;
  }
}

@media (max-width: map-get($grid-breakpoints, xs)) {
  .data-container {
    flex-direction: column;
  }
  .data-list {
    padding-left: 0px;
    padding-top: 40px;
  }
}
</style>
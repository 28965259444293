<template>
  <v-col cols="12" lg="6" class="responsive-card">
    <v-card elevation="4" class="monitoring-card">
      <v-card-title>
        <h6 class="mb-4">{{ title }}</h6>
      </v-card-title>
      <v-card-text class="data-container">
        <ul class="item-list" v-if="!loading">
          <li class="item" v-for="(item, index) in data" :key="index">
            <div class="item__content">
              <div class="item__labels" >
                <div class="item__icon-container" v-if="item.img || item.icon">
                  <v-img
                    max-width="24"
                    max-height="24"
                    v-if="item.img"
                    :src="`/assets/${item.img}`"
                  />
                  <v-icon size="24" v-else-if="item.icon">{{
                    item.icon
                  }}</v-icon>
                </div>

                <span class="text-truncate">
                  <b class="font-weight-600 text-truncate">
                    {{ item.label }}
                  </b>
                </span>
              </div>
              <div class="item__data">
                <span
                  ><b class="font-weight-600">{{ item.data }}</b></span
                >
                <progress-chart
                  :value="item.data"
                  :color="item.color"
                  :maxValue="maxValue"
                />
              </div>
            </div>
          </li>
        </ul>
        <shares-card-skeleton v-else />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import ProgressChart from "../../charts/ProgressChart.vue";
import SharesCardSkeleton from "./SharesCardSkeleton.vue";

export default {
  components: {
    ProgressChart,
    SharesCardSkeleton,
  },
  data: () => ({}),
  computed: {
    maxValue: function () {
      let max = 0;
      this.data.map((item) => {
        max += item.data;
      });

      return max;
    },
  },
  props: {
    loading: Boolean,
    title: {
      type: String,
      default: "Percent Chart",
    },
    data: {
      type: Array,
      default: () => [
        {
          img: "",
          icon: "",
          data: 40,
          label: "a",
        },
      ],
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  padding-left: 0px;
}

.v-image::v-deep {
  .v-image__image {
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%) inset;
  }
}

.item {
  list-style-type: none;
  font-weight: $font-weight-medium;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: map-get($text, headings);
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__data {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }
  &__labels {
    display: flex;
    max-width: calc(100% - 150px)
  }
  &__icon-container {
    margin-right: 16px;
    margin-top: 4px;
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    .item {
      &__content {
        flex-direction: column;
      }
      &__data {
        justify-content: space-between;
        .v-progress-linear {
          max-width: 50%;
        }
      }
    }
  }
}
</style>

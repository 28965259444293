<template>
  <v-col cols="12" class="responsive-card">
    <v-card elevation="4" class="monitoring-card">
      <template v-if="!loading">
      <v-card-title>
        <h6 class="mb-4">{{ title }}</h6>
      </v-card-title>
      <v-card-text>
        <div class="monitoring-card__indicators-container">
          <monitoring-value-indicator
            v-for="(item, index) in dataIndicators"
            :key="index"
            :title="item.label"
            :value="item.value"
            :delta="item.delta"
            :color="item.color"
          />
        </div>
      </v-card-text>
      <v-card-text>
        <monitoring-chart v-if="!loading" :style="options" :chartdata="data" />
        <div v-else>

        </div>
      </v-card-text>
      </template>
      <chart-card-skeleton v-else />
    </v-card>
  </v-col>
</template>

<script>
import MonitoringChart from "../../charts/MonitoringChart.vue";
import MonitoringValueIndicator from "../../MonitoringValueIndicator.vue";
import ChartCardSkeleton from '../ChartCardSkeleton.vue'

export default {
  components: {
    MonitoringValueIndicator,
    MonitoringChart,
    ChartCardSkeleton
  },
  data: () => ({
    options: {
      position: "relative",
      height: "220px",
    },
  }),
  props: {
    loading: Boolean,
    title: String,
    dataIndicators: {
      type: Array,
    },
    data: {
      type: Object,
      default: () => ({
        current: 0,
        delta: 0,
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.monitoring-card {
  .monitoring-card__item {
    margin-right: 48px;
    h1 {
      position: relative;
      padding-left: 16px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        background: map-get($primary, base);
        top: calc(50% - 4px);
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }
  .monitoring-card__indicator {
    margin-left: 8px;
  }

  &__indicators-container {
    display: flex;
    justify-content: flex-start;
    align-content: stretch;
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    &__indicators-container {
      flex-direction: row;
      flex-wrap: wrap;
      .item::v-deep {
        margin-right: 0px;
        min-width: 50%;
        &.item {
          margin-top: 6px;
          margin-bottom: 6px;
          &__indicator {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
</style>
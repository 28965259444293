<template>
  <ul class="item-list d-flex flex-column">
    <div v-for="item in 5" :key="item" class="mb-4">
      <div
        class="
          d-flex
          justify-space-between
          align-center
          w-100
          skeleton-container
        "
      >
        <v-skeleton-loader type="avatar, heading" class="indicator" />
        <v-skeleton-loader type="heading, text" class="progress" />
      </div>
    </div>
  </ul>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.skeleton-container {
  @media (max-width: map-get($grid-breakpoints, sm)) {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center !important;
  }
}

.v-skeleton-loader {
  &.indicator::v-deep {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 280px;
    .v-skeleton-loader__avatar {
      width: 21px;
      height: 15px;
      margin-right: 10px;
      border-radius: 2px;
    }
    .v-skeleton-loader__heading {
      width: 80px;
      height: 10px;
      border-radius: 3px;
    }
  }
  &.progress::v-deep {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 200px;
    @media (max-width: map-get($grid-breakpoints, sm)) {
      margin-top: 8px;
      justify-content: space-between !important;
      width: 100%;
      .v-skeleton-loader__text {
        width: 100%;
        max-width: 50%;
      }
    }
    .v-skeleton-loader__heading {
      height: 10px;
      width: 32px;
      margin-right: 10px;
    }
    .v-skeleton-loader__text {
      max-width: 100px;
      height: 4px;
      margin: 0px;
    }
  }
}
</style>
<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,

  props: {
    chartdata: {
      type: Array,
      default: () => [50, 30, 20],
    },
    colors: {
      type: Array,
      default: () => ["#83CC80", "#EF9840", "#CA7699"],
    },
    labels: {
      type: Array,
      default: () => ["a", "b", "c"],
    },
  },

  data: () => ({
    options: {
      position: "relative",
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: true,
      cutoutPercentage: 0,
      rotation: 0,
      datasets: {
        borderWidth: 0,
      },
      legend: {
        display: false,
      },
      hover: { mode: null },
    },
  }),
  mounted() {
    this.renderChart(
      {
        datasets: [
          {
            data: this.chartdata,
            borderWidth: 0,
            backgroundColor: this.colors,
          },
        ],
        labels: this.labels,
      },
      this.options
    );
  },
};
</script>
<template>
  <div class="item" :class="small ? 'item--small' : ''">
    <p class="p-4 mb-1">{{ title }}</p>
    <div class="d-flex align-center item__indicator">
      <div
        class="item__value-indicator"
        v-if="color"
        :style="{ color: color ? color : 'transparent' }"
      ></div>
      <h1 :class="!color ? 'pl-0' : ''" class="item__value">{{ value }}</h1>
      <delta-indicator :value="delta" />
    </div>
  </div>
</template>

<script>
import DeltaIndicator from "./DeltaIndicator.vue";

export default {
  components: {
    DeltaIndicator,
  },
  props: {
    small: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
    },
    delta: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    color: {
      type: [String, Boolean],
      default: "#83CC80",
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-right: 48px;
  &__value {
    font-weight: $font-weight-extrabold;
  }
  .item__value-indicator {
    position: relative;
    overflow: visible;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      background: currentColor;
      top: calc(50% - 4px);
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
  h1 {
    padding-left: 16px;
  }
  &.item--small {
    .item__value {
      font-size: $font-size-h3;
      line-height: $line-height-h3;
    }
  }
}
</style>
<template>
  <v-progress-linear
    :buffer-value="100"
    :value="percent"
    background-color="#E5E5F0"
    :color="color"
  >
  </v-progress-linear>
</template>

<script>
export default {
  props: {
    maxValue: {
        type: Number,
        default: 100
    },
    value: {
      type: Number,
      default: 50,
    },
    color: {
      type: String,
      default: "#83CC80",
    },
  },
  computed: {
      percent: function() {
          return Math.ceil(this.value / this.maxValue * 100)
      }
  }
};
</script>

<style lang="scss" scoped>
.v-progress-linear {
  min-width: 100px;
  overflow: visible;
  height: 6px;

}

.v-progress-linear::v-deep {
  .v-progress-linear {
    &__background {
      border-radius: 3px;
      margin-left: 2px;
    }
    &__determinate {
      border-radius: 3px;
    }
  }
}
</style>
<template>
  <v-card-text class="data-container">
    <div class="chart">
      <v-skeleton-loader class="speed" type="avatar" />
    </div>
    <ul class="data-list">
      <div v-for="item in 5" :key="item" class="mb-3">
        <div class="d-flex justify-space-between w-100">
          <v-skeleton-loader type="avatar, text" class="indicator" />
          <v-skeleton-loader type="text" class="text" />
        </div>
      </div>
    </ul>
  </v-card-text>
</template>




<script>
export default {};
</script>
 
<style lang="scss" scoped>
.data-list {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.v-skeleton-loader {
  &.speed::v-deep {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 180px;
    min-height: 180px;
    .v-skeleton-loader__avatar {
      width: 180px;
      height: 180px;
    }
  }
  &.indicator::v-deep {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 24px;
    
    .v-skeleton-loader__avatar {
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
    .v-skeleton-loader__text {
      width: 80px;
      height: 10px;
      border-radius: 3px;
            margin-left: 16px;

    }
  }
  &.text::v-deep {
    .v-skeleton-loader__text {
      width: 32px;
      height: 10px;
      border-radius: 3px;
    }
  }
}

.v-skeleton-loader::v-deep {
  .v-skeleton-loader__text {
    width: 40px;
    height: 10px;
    margin: 0;
  }
}
</style>
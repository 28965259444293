<template>
  <div>
    <canvas ref="canvas" />
  </div>
</template>

<script>
import { Chart } from "chart.js";
import './RoundedDoughnut.js'


export default {
  props: {
    chartdata: {
      type: Array,
      default: () => [50, 30, 20],
    },
    colors: {
      type: Array,
      default: () => ["#83CC80", "#EF9840", "#CA7699"],
    },
    labels: {
      type: Array,
      default: () => ["a", "b", "c"],
    },
  },

  data: () => ({
    options: {
      position: "relative",
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: true,
      cutoutPercentage: 75,
      legend: {
        display: false,
      },
      hover: { mode: null },
    },
  }),
  methods: {
    renderChart: function () {
      const ctx = this.$refs.canvas;
      this.chart = new Chart(ctx, {
        type: "RoundedDoughnut",
        data: {
          datasets: [
            {
              data: this.chartdata,
              backgroundColor: this.colors,
            },
          ],
          labels: this.labels,
        },
        options: this.options,
      });
    },
  },
  mounted() {
    this.renderChart();
  },
};
</script>
